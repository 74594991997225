<template>
  <a-tabs default-active-key="email">
    <a-tab-pane key="email" tab="邮件消息">
      <iss-email-pane />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { Tabs } from 'ant-design-vue';
import IssEmailPane from './components/email';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    IssEmailPane,
    // messagePane
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    changeTab() {
      this.eventId = '257';
      // setTimeout(() => {
      if (this.filter === 'sms') {
        // this.smsContentListCom.query(this.eventId);//获取短信列表
      } else if (this.filter === 'email') {
        // this.emailContentListCom.query(this.eventId);//获取邮件列表
      } else if (this.filter === 'weChat') {
        // this.weChatContentListCom.query(this.eventId);//获取模板消息
      } else if (this.filter === 'weChatImageText') {
        // this.weChatImageTextListCom.query(this.eventId);
      }
      // });
    },
  },
};
</script>
